<template>
  <mobile-screen
    :header="true"
    screen-class="resources-availability gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resources-help-online-edit-unavailability'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "resources",
              "edit-resource-unavailability",
              "edit-resource-unavailability"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      ref="editUnavailabilityForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form tiny-input change-password-form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <span class="label clebex-item-label">{{
              displayLabelName(
                "resources",
                "edit-resource-unavailability",
                "not-in-use-from-date"
              )
            }}</span>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.date_from || errors.date_to }"
        >
          <span class="error-message" v-if="errors.date_from || errors.date_to">
            {{ errors.date_from || errors.date_to }}
          </span>
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <button
                  class="clebex-item-range-cta"
                  @click.prevent="
                    showDateFrom = !showDateFrom;
                    showDateTo = false;
                  "
                >
                  {{ selectedDateFromParsed }}
                </button>
              </li>
              <li class="clebex-item-range separator">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </li>
              <li class="clebex-item-range to">
                <button
                  @click.prevent="
                    showDateTo = !showDateTo;
                    showDateFrom = false;
                  "
                  class="clebex-item-range-cta"
                >
                  {{ selectedDateToParsed }}
                </button>
              </li>
            </ul>
          </div>
        </li>
        <li class="clebex-item-section-item" v-if="showDateFrom">
          <div class="clebex-item-content-wrapper">
            <select-date
              :selectDateFn="selectDateFrom"
              :show-date-picker="showDateFrom"
              :date-to-watch="selectedDateFrom"
              date-format="YYYY-MM-DD"
              display-date-format="D MMMM YYYY"
              :selected-date="selectedDateFrom"
            />
          </div>
        </li>
        <li class="clebex-item-section-item" v-if="showDateTo">
          <div class="clebex-item-content-wrapper">
            <select-date
              :selectDateFn="selectDateTo"
              :show-date-picker="showDateTo"
              :date-to-watch="selectedDateTo"
              date-format="YYYY-MM-DD"
              display-date-format="D MMMM YYYY"
              :selected-date="selectedDateTo"
            />
          </div>
        </li>
      </ul>
      <Field
        name="date_from"
        as="input"
        type="hidden"
        rules="required"
        v-model="selectedDateFrom"
        :disabled="submitting"
      />
      <Field
        name="date_to"
        as="input"
        type="hidden"
        rules="required"
        v-model="selectedDateTo"
        :disabled="submitting"
      />
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapGetters, mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import dayjs from "dayjs";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "EditResourceUnavailability",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDate: defineAsyncComponent(() =>
      import("@/components/global/SelectDate")
    )
  },
  data() {
    return {
      submitting: false,
      showDateFrom: false,
      showDateTo: false,
      selectedDateFrom: false,
      selectedDateTo: false,
      selectedDateFromParsed: null,
      selectedDateToParsed: null,
      helpSlug: "resources-availability-periods"
    };
  },
  created() {
    const { resource } = this;

    if (
      resource &&
      resource.data &&
      this.canItem(this.resource.data, "update")
    ) {
      this.fetchUnavailability();
    } else {
      this.$router.push({ name: "r_resources" });
    }
  },
  watch: {
    $route() {
      const { unavailability_id } = this.$route.params;
      if (unavailability_id) this.fetchUnavailability();
    }
  },
  computed: {
    ...mapState("resource", ["resource"]),
    ...mapState("resourceAvailability", ["unavailability"]),
    ...mapGetters("settings", ["globalDateFormat"]),
    timeFrom() {
      const { selectedHourFrom, selectedMinuteFrom } = this;
      if (selectedHourFrom && selectedMinuteFrom) {
        return `${selectedHourFrom}:${selectedMinuteFrom}`;
      }
      return null;
    },
    timeTo() {
      const { selectedHourTo, selectedMinuteTo } = this;
      if (selectedHourTo && selectedMinuteTo) {
        return `${selectedHourTo}:${selectedMinuteTo}`;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("resourceAvailability", [
      "getUnavailabilities",
      "getUnavailability"
    ]),
    fetchUnavailability() {
      const { resource_id, unavailability_id } = this.$route.params;
      this.getUnavailability({ resource_id, unavailability_id }).finally(() => {
        this.setInitialValues();
      });
    },
    submitForm(values) {
      if (values) {
        this.$store.commit("loader/setScreenLoading", true, { root: true });
        httpServiceAuth
          .put(
            `${apiEndpoints.company.resources}/${this.resource.data.id}/resourceunavailabilities/${this.unavailability.data.id}`,
            values
          )
          .then(() => {
            this.getUnavailabilities(this.resource.data.id);
            this.fetchUnavailability();
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
      }
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    selectDateFrom(date, displayDate) {
      this.selectedDateFrom = date;
      this.selectedDateFromParsed = displayDate;
    },
    selectDateTo(date, displayDate) {
      this.selectedDateTo = date;
      this.selectedDateToParsed = displayDate;
    },
    clearValues() {
      this.selectedDateFromParsed = null;
      this.selectedDateFrom = false;
      this.selectedDateToParsed = null;
      this.selectedDateTo = false;
    },
    setInitialValues() {
      if (this.unavailability) {
        const { data } = this.unavailability;
        if (data) {
          const { date_from, date_to } = data;

          if (date_from && date_to) {
            this.selectedDateFrom = date_from;
            this.selectedDateTo = date_to;

            // yyyy-mm-dd
            // date-format="YYYY-MM-DD"
            // display-date-format="D MMMM YYYY"
            this.selectedDateFromParsed = dayjs(`${date_from}`).format(
              this.globalDateFormat || "D MMMM YYYY"
            );
            this.selectedDateToParsed = dayjs(`${date_to}`).format(
              this.globalDateFormat || "D MMMM YYYY"
            );
          }
        }
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
